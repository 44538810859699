<template>
  <v-app>
    <v-main>
      <app v-if="perro!=null"></app>
      <login v-else></login>
    </v-main>
  </v-app>
</template>
<script>
import App from "../src/views/App"
import Login from "../src/views/Login"
export default {
  name: 'App',
  data: () => ({
    perro:localStorage.getItem("xn8(*&^%$#47b*g3f76gw3*&^fn734gf8q*&^&^&mwmeg934g8whmre7ngN&G#*!NG@*&#GF(!*#gm1n428fm1g3n84gmf*^$&(%$)e3298negmg") 
  }),
  created(){
    
  },
  components:{ 
    'app':App, 
    'login':Login,
  },
};
</script>
